import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c537d772"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tlf-layout"
};
const _hoisted_2 = {
  class: "tlf-top"
};
const _hoisted_3 = {
  class: "tlf-bottom"
};
const _hoisted_4 = {
  class: "tlf-left"
};
const _hoisted_5 = {
  class: "tlf-right"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "top", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "left", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "right", {}, undefined, true)])])]);
}