import DocEditor from "./DocEditor/index.vue";
export default {
  name: 'DialogStage',
  components: {
    DocEditor
  },
  emits: ["update:obj", "toRefresh"],
  props: {
    obj: Object
  },
  data() {
    return {
      addMenuVisible: false,
      delMenuVisible: false,
      changeVisible: false,
      currentWS: [],
      temTable: {
        templateData: [],
        tablecol: [{
          prop: "mbfl",
          label: "模板分类",
          width: 110
        },
        // { prop: "mbmc", label: "文书名称" },
        {
          prop: "mbmc",
          label: "模板名称"
        }, {
          prop: "bz",
          label: "备注",
          width: 90
        }]
      },
      iframeHeight: window.innerHeight - 180 + 'px',
      defaultProps: {
        children: "children",
        label: "label"
      },
      bookTreeProps: {
        children: "children",
        label: "mbmc"
      },
      objs: {},
      newFormData: {},
      NumberOptions: [],
      userData: [],
      loading: true,
      templateLoading: true,
      wsLoading: false,
      activeName: "first",
      dwxxData: [],
      // 产品信息左侧树菜单
      treeList: [{
        id: 0,
        label: "农产品",
        parent_id: -1,
        children: [{
          id: 1,
          label: "有机产品",
          parent_id: 0,
          children: []
        }, {
          id: 2,
          label: "绿色产品",
          parent_id: 0,
          children: []
        }, {
          id: 3,
          label: "无公害产品",
          parent_id: 0,
          children: []
        }]
      }],
      resetVisible: false,
      mbData: {
        mbmc: "",
        bz: "",
        mbfl: "",
        mbmc: "",
        id: -1,
        mbtext: "",
        sjcb_id: -1
      },
      originalData: {},
      baseInfo: {
        jbxx: {
          id: 0,
          dxlb: "",
          mc: "",
          tyshxydm: "",
          scjyxkzh: "",
          fzr: "",
          dwdz: "",
          jyz: "",
          xb: "",
          nl: 0,
          sfzh: "",
          zz: "",
          mz: "",
          yb: "",
          dh: "",
          cz: "",
          lxr: "",
          jyfw: "",
          xz: "",
          cun: "",
          code: "",
          sjrq: new Date(),
          org_id: null,
          dwmc: "",
          cydwlxr: "",
          cydwdz: "",
          cydwyb: "",
          lxdh: "",
          cydwcz: "",
          email: ""
        },
        cp: {
          id: 0,
          cpmc: "",
          scdwmc: "",
          sb: "",
          cf: "",
          zxbz: "",
          xkz: "",
          ggxh: "",
          cp_txdz: "",
          cp_yb: "",
          cp_lxr: "",
          bz: "",
          dj: "",
          bs: "",
          scrq: new Date(),
          cprzqk: "",
          zsbh: "",
          cysl: 0,
          cyjs: 0,
          cycd: "",
          xsdj: 0,
          cpxl: "",
          jyjg: "",
          sjcb_id: 1
        }
      },
      jbxx: {
        id: 0,
        dxlb: "",
        mc: "",
        tyshxydm: "",
        scjyxkzh: "",
        fzr: "",
        dwdz: "",
        jyz: "",
        xb: "",
        nl: 0,
        sfzh: "",
        zz: "",
        mz: "",
        yb: "",
        dh: "",
        cz: "",
        lxr: "",
        jyfw: "",
        xz: "",
        cun: "",
        code: "",
        sjrq: new Date(),
        org_id: null,
        dwmc: "",
        cydwlxr: "",
        cydwdz: "",
        cydwyb: "",
        lxdh: "",
        cydwcz: "",
        email: ""
      },
      cp: {
        id: 0,
        cpmc: "",
        scdwmc: "",
        sb: "",
        cf: "",
        zxbz: "",
        xkz: "",
        ggxh: "",
        cp_txdz: "",
        cp_yb: "",
        cp_lxr: "",
        bz: "",
        dj: "",
        bs: "",
        scrq: new Date(),
        cprzqk: "",
        zsbh: "",
        cysl: 0,
        cyjs: 0,
        cycd: "",
        xsdj: 0,
        cpxl: "",
        jyjg: "",
        sjcb_id: 1
      },
      bookTreeList: [],
      tableScjr: {
        currentScjrData: [],
        scjrData: [],
        tableCount: 0,
        // 分页数据总数
        currpage: 1,
        // 当前页码
        pagesize: 10,
        params: {
          dxlb: '单位',
          xz: '',
          cun: '',
          mc: ''
        },
        tablecol: [{
          prop: 'dxlb',
          label: '对象类别'
        }, {
          prop: 'mc',
          label: '单位（个人）名称'
        }, {
          prop: 'tyshxydm',
          label: '统一社会信用代码'
        }, {
          prop: 'fzr',
          label: '法人负责人'
        }, {
          prop: 'jyz',
          label: '经营者'
        }, {
          prop: 'dwdz',
          label: '地址'
        }, {
          prop: 'sfzh',
          label: '身份证号',
          width: "180"
        }, {
          prop: 'zz',
          label: '住址'
        }, {
          prop: 'xb',
          label: '性别'
        }, {
          prop: 'nl',
          label: '年龄'
        }]
      },
      tableCpxx: {
        currentCpxxData: [],
        cpxxData: [],
        tableCount: 0,
        // 分页数据总数
        currpage: 1,
        // 当前页码
        pagesize: 10,
        cplx: '农产品',
        currentNcplx: "有机产品",
        params: {},
        paramsYJ: {
          ZSBH: '',
          QYMC: ''
        },
        paramsLS: {
          BZBH: '',
          QYMC: '',
          CPMC: ''
        },
        paramsWGH: {
          ZSBH: '',
          SQRQC: '',
          CPMC: ''
        },
        tablecol: [{
          prop: 'zsbh',
          label: '证书编号',
          width: "240"
        }, {
          prop: 'qymc',
          label: '企业名称',
          width: "240"
        }, {
          prop: 'cpmchzclhzsl',
          label: '产品名称、获证产量(吨)、获证数量'
        }],
        tablecolYJ: [{
          prop: 'zsbh',
          label: '证书编号'
        }, {
          prop: 'qymc',
          label: '企业名称'
        }, {
          prop: 'cpmchzclhzsl',
          label: '产品名称、获证产量(吨)、获证数量'
        }],
        tablecolLS: [{
          prop: 'bzbh',
          label: '标志编号'
        }, {
          prop: 'qyxxm',
          label: '企业信息码'
        }, {
          prop: 'qymc',
          label: '企业名称'
        }, {
          prop: 'cpmc',
          label: '产品名称'
        }, {
          prop: 'cplb',
          label: '产品类别'
        }, {
          prop: 'zcsb',
          label: '注册商标'
        }],
        tablecolWGH: [{
          prop: 'zsbh',
          label: '证书编号'
        }, {
          prop: 'sqrqc',
          label: '申请人全称'
        }, {
          prop: 'cpmc',
          label: '产品名称'
        }, {
          prop: 'sf',
          label: '省份'
        }, {
          prop: 'zsyxq',
          label: '证书有效期'
        }]
      }
    };
  },
  methods: {
    generateArray() {
      const start = 1; // 开始值
      const end = 200; // 结束值
      const array = [];
      for (let i = start; i <= end; i += 1) {
        const formattedValue = i.length >= 3 ? i : (new Array(3).join('0') + i).slice(-3);
        const item = {
          value: formattedValue,
          label: formattedValue
        };
        array.push(item);
      }
      this.NumberOptions = array;
    },
    async initForm() {
      let formData = {};
      let data = JSON.parse(JSON.stringify(this.obj.allData));
      for (let key in data) {
        this.baseInfo[key] = data[key];
      }
      // this.baseInfo = JSON.parse(JSON.stringify(this.obj.allData))
      let allData = {};
      allData = Object.assign({}, this.baseInfo.cp, this.baseInfo.jbxx);
      if (Object.keys(this.baseInfo).length > 0) {
        allData['jbxx_id'] = this.baseInfo.jbxx.id;
        allData['cp_id'] = this.baseInfo.cp.id;
      }
      this.originalData = allData;
      if (Object.keys(allData).length > 0) {
        let sjcbCode = allData.code.split("-");
        formData['one'] = sjcbCode[0];
        formData['two'] = sjcbCode[1];
        formData['three'] = sjcbCode[2];
        formData['four'] = sjcbCode[3];
        formData['date'] = allData.sjrq;
        for (let key in allData) {
          formData[key] = allData[key];
        }
        this.tableScjr.params.mc = allData['mc'];
        //调用iframe的方法，将基本信息传入表格
        var frame = document.getElementById("formFrame");
        frame.onload = () => {
          frame.contentWindow.initChildForm(formData, Object.keys(formData));
        };
      }
    },
    getBookTree() {
      this.bookTreeList = [];
      this.$axios.get("api/BusinessNcpcj/GetWsCatalog", {
        params: {
          mbfl: this.obj.moduletitle,
          id: this.cp.sjcb_id
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.bookTreeList.push(res.data.result);
          this.bookTreeList[0].label = "文书列表";
          this.bookTreeList[0].is_catalog = true;
          this.templateLoading = false;
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    saveTemplate(mbResult) {
      // 判断文书数据是否已存在，存在调用update，不存在调用add
      let url = "";
      let currentData = {};
      if (!mbResult["sjcb_id"]) {
        mbResult["sjcb_id"] = this.cp.sjcb_id;
      }
      if (!mbResult["ws_id"]) {
        mbResult["ws_id"] = mbResult.id;
      }
      this.wsLoading = true;
      this.$axios.get("/api/BusinessHandle/GetNcpcjWsById", {
        params: {
          jbxxid: mbResult.sjcb_id,
          wsid: mbResult.ws_id
        }
      }).then(res => {
        if (res.data.code === 200) {
          currentData = res.data.result;
          for (let key in currentData) {
            if (key !== "id") {
              currentData[key] = mbResult[key];
            }
          }
          url = "/api/BusinessNcpcj/UpdateWs";
        } else {
          currentData = mbResult;
          url = "/api/BusinessNcpcj/AddWs";
        }
        this.$axios.post(url, currentData).then(res => {
          if (res.status == 200 && res.data.code == 200) {
            this.$ElMessage.success("保存成功");
            this.getBookTree();
          }
        }).catch(e => {
          this.$ElMessage.warning("保存失败");
        });
        this.wsLoading = false;
      });
    },
    // 重置文书
    async resetDocument() {
      this.changeVisible = false;
      if (this.currentWS && this.currentWS.is_catalog === false) {
        this.newFormData = window.frames['formFrame'].saveBtn();
        for (let key in this.newFormData) {
          if (this.newFormData[key] !== null && this.newFormData[key] !== undefined) {
            if (this.newFormData[key] !== this.originalData[key]) {
              this.changeVisible = true;
            }
          }
        }
        if (this.changeVisible) {
          await this.$confirm('基本信息已修改，是否选择保存？（不保存的修改内容无法置换在文书中）?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.saveAllData();
          }).catch(() => {
            // return reject()
          });
        }
      } else {
        this.$ElMessage.info("请选择文书后重置");
      }
      this.resetVisible = true;
      // this.$refs.docEditor.setDataField();
      this.$nextTick(() => {
        this.$refs.docEditor.initWriter();
      });
    },
    rightKeyClick(MouseEvent, data, menus) {
      this.addMenuVisible = false;
      this.delMenuVisible = false;
      this.currentWS = data;
      if (menus === '#addMenu' && data.is_catalog && data.id !== 0) {
        this.addMenuVisible = true;
      } else if (!data.is_catalog) {
        this.delMenuVisible = true;
        menus = '#delMenu';
      }
      var menu = document.querySelector(menus);
      document.addEventListener('click', this.foo); // 给整个document添加监听鼠标事件，点击任何位置执行foo方法
      menu.style.display = "block";
      menu.style.left = MouseEvent.clientX - 0 + 'px';
      menu.style.top = MouseEvent.clientY - 80 + 'px';
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.addMenuVisible = false;
      this.delMenuVisible = false;
      document.removeEventListener('click', this.foo); // 关掉监听
    },
    //选择模板、判断是否已存在文书（存在展示文书，不存在展示模板）
    async onsubmitTem() {
      this.mbData = {};
      let selectdata = this.$refs.temTable.getSelectionRows();
      if (selectdata.length === 1) {
        let res = await this.$axios.get("/api/BusinessHandle/GetNcpcjWsById", {
          params: {
            jbxxid: this.baseInfo.cp.sjcb_id,
            wsid: selectdata[0].id
          }
        });
        if (res.data.code === 200) {
          this.mbData = res.data.result;
          this.resetVisible = false;
        } else {
          this.mbData = selectdata[0];
          this.resetVisible = true;
        }
        this.$nextTick(() => {
          this.$refs.docEditor.reload();
        });
        this.$refs.dialogTem.visible = false;
      } else {
        this.$ElMessage.info("请选择一行数据");
      }
    },
    async getTemplate() {
      let data = [];
      let result = await this.$axios.get("/api/Template/GetXtmbByID", {
        params: {
          id: this.currentWS.id
        }
      });
      if (result.data.code === 200) {
        data = result.data.result;
      }
      this.$axios.get("/api/Template/GetXtmbByMbmc", {
        params: {
          mbfl: data.mbfl,
          mbmc: data.mbmc
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.temTable.templateData = res.data.result;
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    createWS() {
      this.getTemplate();
      this.$refs.dialogTem.visible = true;
    },
    delWS() {
      this.$axios.post("api/BusinessNcpcj/DeleteWs?id=" + this.currentWS.id).then(res => {
        if (res.data.code === 200) {
          this.delMenuVisible = false;
          this.getBookTree();
        } else {
          this.$ElMessage.error(res.data.message);
        }
      }).catch();
    },
    // 时间转字符串
    convertDate2Str(date) {
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let gettime = yy + "-" + mm + "-" + dd;
      return gettime;
    },
    // 处理数据
    handelData() {
      this.newFormData = window.frames['formFrame'].saveBtn();
      let jbxx = this.baseInfo.jbxx;
      let cp = this.baseInfo.cp;
      for (let key in jbxx) {
        jbxx[key] = this.newFormData[key];
      }
      if (this.newFormData['jbxx_id']) {
        jbxx['id'] = this.newFormData['jbxx_id'];
      }
      for (let key in cp) {
        cp[key] = this.newFormData[key];
      }
      if (this.newFormData['cp_id']) {
        cp['id'] = this.newFormData['cp_id'];
      }
      let dwxxData = this.dwxxData;
      if (dwxxData) {
        jbxx['dwmc'] = dwxxData['dwmc'];
        jbxx['cydwdz'] = dwxxData['dwdz'];
        jbxx['cydwyb'] = dwxxData['yb'];
        jbxx['lxdh'] = dwxxData['lxdh'];
        jbxx['cydwcz'] = dwxxData['cz'];
        jbxx['email'] = dwxxData['email'];
      }
    },
    // 保存按钮
    async saveAllData() {
      let url = "";
      this.handelData();
      if (this.obj.secondVisible) {
        url = "api/BusinessNcpcj/UpdateNcpcj";
      } else {
        url = "api/BusinessNcpcj/AddNcpcj";
      }
      this.$axios.post(url, this.baseInfo).then(res => {
        if (res.data.code === 200) {
          this.obj.secondVisible = true;
          this.cp.sjcb_id = res.data.result;
          this.getBookTree();
          this.originalData = window.frames['formFrame'].saveBtn();
          this.$emit('toRefresh');
          this.$ElMessage.success("操作成功");
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    // tab切换
    tabClick(tab, event) {
      // this.mbData = ""
    },
    // 查询受抽检人信息
    searchScjr() {
      this.$refs.dialogScjr.visible = true;
      this.tableScjr.currpage = 1;
      this.getScjrTable();
    },
    getScjrTable() {
      let url = "/api/Supervise/GetJgdx";
      this.$axios.get(url, {
        params: {
          page: this.tableScjr.currpage,
          limit: this.tableScjr.pagesize,
          ...this.tableScjr.params
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.tableScjr.tableCount = res.data.count;
          if (res.data.data) {
            this.tableScjr.scjrData = res.data.data;
          }
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    //搜索重置
    searchClearScjr() {
      this.tableScjr.params.dxlb = '';
      this.tableScjr.params.mc = '';
      this.getScjrTable();
    },
    //选择受抽检人
    onsubmitScjr() {
      this.tableScjr.currentScjrData = this.$refs.scjr_table.getSelectionRows();
      window.frames['formFrame'].updateScjrData(this.tableScjr.currentScjrData);
      this.$refs.dialogScjr.visible = false;
    },
    // 查询农产品信息
    searchCpxx() {
      this.$refs.dialogCpxx.visible = true;
      this.tableCpxx.currpage = 1;
      let val = {
        label: this.tableCpxx.currentNcplx
      };
      this.handleNodeClick(val);
      // this.getCpxxTable();
    },
    getCpxxTable() {
      let url = "";
      if (this.tableCpxx.currentNcplx === "有机产品") {
        url = "/api/FarmProduct/GetYjsp";
      } else if (this.tableCpxx.currentNcplx === "绿色产品") {
        url = "/api/FarmProduct/GetLssp";
      } else {
        url = "/api/FarmProduct/GetWghncp";
      }
      this.$axios.get(url, {
        params: {
          page: this.tableCpxx.currpage,
          limit: this.tableCpxx.pagesize,
          ...this.tableCpxx.params
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.tableCpxx.tableCount = res.data.count;
          if (res.data.data) {
            this.tableCpxx.cpxxData = res.data.data;
          }
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    //搜索重置
    searchClearCpxx() {
      this.tableCpxx.params.ZSBH = '';
      this.tableCpxx.params.QYMC = '';
      this.tableCpxx.params.BZBH = '';
      this.tableCpxx.params.QYMC = '';
      this.tableCpxx.params.CPMC = '';
      this.tableCpxx.params.ZSBH = '';
      this.tableCpxx.params.SQRQC = '';
      this.tableCpxx.CPMC = '';
      this.getCpxxTable();
    },
    //选择产品
    onsubmitCpxx() {
      this.tableCpxx.currentCpxxData = this.$refs.cpxx_table.getSelectionRows();
      window.frames['formFrame'].updateCpxxData(this.tableCpxx.currentNcplx, this.tableCpxx.currentCpxxData);
      this.$refs.dialogCpxx.visible = false;
    },
    //更新
    updateNcplx(val) {
      this.tableCpxx.currentNcplx = val;
    },
    //左侧树形菜单节点点击 
    handleNodeClick(val) {
      this.tableCpxx.currentNcplx = val.label;
      if (val.label === "有机产品") {
        this.tableCpxx.params = this.tableCpxx.paramsYJ;
        this.tableCpxx.tablecol = this.tableCpxx.tablecolYJ;
      } else if (val.label === "绿色产品") {
        this.tableCpxx.params = this.tableCpxx.paramsLS;
        this.tableCpxx.tablecol = this.tableCpxx.tablecolLS;
      } else {
        this.tableCpxx.params = this.tableCpxx.paramsWGH;
        this.tableCpxx.tablecol = this.tableCpxx.tablecolWGH;
      }
      this.getCpxxTable();
    },
    //切换模板 
    async bookNodeClick(val) {
      this.mbData = {};
      this.currentWS = val;
      this.addMenuVisible = false;
      this.delMenuVisible = false;
      if (!val.is_catalog) {
        let res = await this.$axios.get("/api/BusinessNcpcj/GetWsById", {
          params: {
            id: val.id
          }
        });
        if (res.data.code === 200) {
          this.mbData = res.data.result;
        } else {
          this.$ElMessage.error(res.data.message);
        }
      } else {
        this.mbData = "";
      }
      this.$nextTick(() => {
        this.resetVisible = false;
        this.$refs.docEditor.reload();
      });
    },
    // 页码
    handleCurrentChange(cpage) {
      this.tableScjr.currpage = cpage;
      this.tableCpxx.currpage = cpage;
      this.getScjrTable();
      this.getCpxxTable();
    },
    // 每页条数
    handleSizeChange(psize) {
      this.tableScjr.pagesize = psize;
      this.tableCpxx.pagesize = psize;
      this.getScjrTable();
      this.getCpxxTable();
    },
    getDwxx() {
      this.$axios.get("/api/Business/GetDwxx").then(res => {
        if (res.data.code === 200) {
          this.dwxxData = res.data.result;
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    // 获取用户名
    getUerName() {
      this.$axios.get("/web/Login/GetLoginName").then(res => {
        if (res && res.data.code === 200) {
          this.userData = res.data.result;
        }
      });
    },
    listInfoToIframe() {
      let _this = this;
      _this.objs = _this.obj;
      _this.objs.NumberOptions = _this.NumberOptions;
      _this.objs.userName = _this.userData.name;
      return _this.objs;
    }
  },
  created() {
    let _this = this;
    //这里可放到全局，提供给子 iframe 调用
    window[_this.objs] = _this => {
      _this.listInfoToIframe();
    };
  },
  mounted() {
    window.listInfoToChild = this.listInfoToIframe;
    window.searchScjr = this.searchScjr;
    window.searchCpxx = this.searchCpxx;
    window.updateNcplx = this.updateNcplx;
    this.getUerName();
    this.initForm();
    this.getDwxx();
    this.generateArray();
    if (this.obj.secondVisible) {
      this.cp.sjcb_id = this.obj.allData.cp.sjcb_id;
      this.getBookTree();
    }
  }
};