import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menuList, (item, index) => {
    return _openBlock(), _createBlock(_component_el_button, {
      type: item.dom_class,
      key: index,
      icon: item.icon_name,
      onClick: $event => $options.HeadClick(item.dom_id)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1)]),
      _: 2
    }, 1032, ["type", "icon", "onClick"]);
  }), 128);
}