export default {
  name: 'tablePagination',
  props: {
    showSelectColumn: {
      type: Boolean,
      default: true,
      required: false
    },
    // 是否显示复选框
    showIndexColumn: {
      type: Boolean,
      default: false,
      required: false
    },
    // 是否显示索引
    tablecol: {
      type: Array,
      default: [],
      required: false
    },
    // 表头数据
    url: {
      type: String,
      default: "",
      required: false
    },
    // 请求url
    params: {
      type: Object,
      default: {},
      required: false
    },
    // 参数
    pagesize: {
      type: Number,
      default: 5,
      required: false
    },
    // 单页数据量
    pageSizes: {
      type: Array,
      default: [5, 10, 15, 20, 30, 40],
      required: false
    }
  },
  data() {
    return {
      tableData: [],
      // 表格数据
      tableCount: 0,
      // 分页数据总数
      currpage: 1 // 当前页码
    };
  },
  methods: {
    //获取数据
    getTable() {
      let url = this.url;
      this.$axios.get(url, {
        params: {
          page: this.currpage,
          limit: this.pagesize,
          ...this.params
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.tableCount = res.data.count;
          if (res.data.data) {
            this.tableData = res.data.data;
          } else {
            this.tableData = res.data.result;
          }
        } else {
          this.$ElMessage.error(res.data.message);
        }
      });
    },
    //选择数据发生变化时
    getRowSelection() {
      return this.$refs.comTable.getSelectionRows();
    },
    // 插入按钮的方法
    scopeClick(id) {
      this.$emit('scopeClick', id);
    },
    // 删除后更新数据
    refreshDelete() {
      let deleteNum = this.$refs.comTable.getSelectionRows().length;
      let totalPage = Math.ceil((this.tableCount - deleteNum) / this.pagesize);
      this.currpage = this.currpage > totalPage ? totalPage : this.currpage;
      this.currpage = this.currpage < 1 ? 1 : this.currpage;
      this.getTable();
    },
    // 清空表格
    clearSelection() {
      this.$refs.comTable.clearSelection();
    },
    // 行点击事件，触发父页面事件
    rowClick(row, column) {
      this.$emit('rowClick', row);
    },
    // 单元格点击事件，多行显示的单元格点击出弹框
    cellClick(row, column) {
      this.tablecol.forEach(item => {
        if (item.label === column.label) {
          if (item.msgShow && item.msgShow === true) {
            this.$alert(row[item.prop], column.label, {
              confirmButtonText: '确定',
              draggable: true,
              customClass: 'msgbox'
            }).catch(() => {});
          }
        }
      });
    }
  },
  mounted() {
    this.getTable();
  }
};