export default {
  name: 'headMenu',
  props: {
    modulecode: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modulecode', 'buttonClick', 'loaded'],
  data() {
    return {
      menuList: []
    };
  },
  mounted() {
    this.getHeadMenu();
  },
  methods: {
    //获取头部按钮
    getHeadMenu() {
      this.$axios.get("/web/ModuleManager/LoadAuthorizedMenus", {
        params: {
          modulecode: this.modulecode
        }
      }).then(data => {
        if (data.data) {
          this.menuList = data.data;
          this.$nextTick(() => {
            this.$emit('loaded');
          });
        }
      });
    },
    HeadClick(val) {
      this.$emit('buttonClick', val);
    }
  }
};