export default {
  name: 'App',
  data() {
    return {
      windowResize: {
        h: window.innerHeight,
        w: window.innerWidth
      }
    };
  },
  // methods: {
  //   // 初始化
  //   initConfig () {
  //     let _this = this
  //     _this.$router.push({'path': '/'})
  //   },
  // },
  mounted() {
    window.onresize = () => {
      this.windowResize.h = window.innerHeight;
      this.windowResize.w = window.innerWidth;
    };
  }
};