import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/Login/Login'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../pages/Home/Home'),
    children: [
      {
        path: '/OneMaps',
        name: 'OneMaps',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/OneMaps/OneMapUseIndex',
            name: 'OneMapUse',
            component: () => import('../pages/OneMaps/OneMapUseIndex')
          },
        ]
      },
      {
        path: '/info',
        name: 'info',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          // 种子
          {
            path: '/info/zz/sdpz',
            name: 'sdpz',
            component: () => import('../pages/Info/zz/sdpz')
          },
          {
            path: '/info/zz/scjyxkz',
            name: 'scjyxkz',
            component: () => import('../pages/Info/zz/scjyxkz')
          },
          {
            path: '/info/zz/zzscxkz',
            name: 'zzscxkz',
            component: () => import('../pages/Info/zz/zzscxkz')
          },
          {
            path: '/info/zz/jyxkz',
            name: 'jyxkz',
            component: () => import('../pages/Info/zz/jyxkz')
          },
          {
            path: '/info/zz/zjyzs',
            name: 'zjyzs',
            component: () => import('../pages/Info/zz/zjyzs')
          },
          {
            path: '/info/zz/xpbh',
            name: 'xpbh',
            component: () => import('../pages/Info/zz/xpbh')
          },
          {
            path: '/info/zz/tztg',
            name: 'tztg',
            component: () => import('../pages/Info/zz/tztg')
          },
          {
            path: '/info/zz/yzba',
            name: 'yzba',
            component: () => import('../pages/Info/zz/yzba')
          },
          // 肥料
          {
            path: '/info/fl/gjfl',
            name: 'gjfl',
            component: () => import('../pages/Info/fl/gjfl')
          },
          {
            path: '/info/fl/sjfl',
            name: 'sjfl',
            component: () => import('../pages/Info/fl/sjfl')
          },
          {
            path: '/info/fl/wswfl',
            name: 'wswfl',
            component: () => import('../pages/Info/fl/wswfl')
          },
          {
            path: '/info/fl/flbaxx',
            name: 'flbaxx',
            component: () => import('../pages/Info/fl/flbaxx')
          },
          // 农药查询
          {
            path: '/info/nycx/djny',
            name: 'djny',
            component: () => import('../pages/Info/nycx/djny')
          },
          {
            path: '/info/nycx/yxcf',
            name: 'yxcf',
            component: () => import('../pages/Info/nycx/yxcf')
          },
          {
            path: '/info/nycx/xyny',
            name: 'xyny',
            component: () => import('../pages/Info/nycx/xyny')
          },
          {
            path: '/info/nycx/jyny',
            name: 'jyny',
            component: () => import('../pages/Info/nycx/jyny')
          },
          {
            path: '/info/nycx/bhgny',
            name: 'bhgny',
            component: () => import('../pages/Info/nycx/bhgny')
          },
          // 农产品
          {
            path: '/info/ncp/yjcp',
            name: 'yjcp',
            component: () => import('../pages/Info/ncp/yjcp')
          },
          {
            path: '/info/ncp/lssp',
            name: 'lssp',
            component: () => import('../pages/Info/ncp/lssp')
          },
          {
            path: '/info/ncp/wghncp',
            name: 'wghncp',
            component: () => import('../pages/Info/ncp/wghncp')
          },
          {
            path: '/info/ncp/cdrz',
            name: 'cdrz',
            component: () => import('../pages/Info/ncp/cdrz')
          },
          // 兽药
          {
            path: '/info/sy/sycp',
            name: 'sycp',
            component: () => import('../pages/Info/sy/sycp')
          },
          {
            path: '/info/sy/gmpzs',
            name: 'gmpzs',
            component: () => import('../pages/Info/sy/gmpzs')
          },
          {
            path: '/info/sy/scxkz',
            name: 'scxkz',
            component: () => import('../pages/Info/sy/scxkz')
          },
          {
            path: '/info/sy/jksyzc',
            name: 'jksyzc',
            component: () => import('../pages/Info/sy/jksyzc')
          },
          {
            path: '/info/sy/swpq',
            name: 'swpq',
            component: () => import('../pages/Info/sy/swpq')
          },
          {
            path: '/info/sy/cpgs',
            name: 'cpgs',
            component: () => import('../pages/Info/sy/cpgs')
          },
          {
            path: '/info/sy/cjbhg',
            name: 'cjbhg',
            component: () => import('../pages/Info/sy/cjbhg')
          },
          {
            path: '/info/sy/jysy',
            name: 'jysy',
            component: () => import('../pages/Info/sy/jysy')
          },
          {
            path: '/info/sy/syxyq',
            name: 'syxyq',
            component: () => import('../pages/Info/sy/syxyq')
          },
          // 饲料
          {
            path: '/info/slcx/slcx',
            name: 'slcx',
            component: () => import('../pages/Info/slcx/slcx')
          },
          // 工业许可证
          {
            path: '/info/gyxkz/gyxkz',
            name: 'gyxkz',
            component: () => import('../pages/Info/gyxkz/gyxkz')
          },
          // 法律法规
          {
            path: '/info/flfg/flfg',
            name: 'flfg',
            component: () => import('../pages/Info/flfg/flfg')
          },
          {
            path: '/info/flfg/wfxw',
            name: 'wfxw',
            component: () => import('../pages/Info/flfg/wfxw')
          },
          {
            path: '/info/flfg/zyclq',
            name: 'zyclq',
            component: () => import('../pages/Info/flfg/zyclq')
          },
        ]
      },
      {
        path: '/work',
        name: 'work',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/work/cpcj/ncpcj',
            name: 'ncpcj',
            component: () => import('../pages/Work/cpcj/ncpcj')
          },
          {
            path: '/work/cpcj/nytrpcj',
            name: 'nytrpcj',
            component: () => import('../pages/Work/cpcj/nytrpcj')
          },
          {
            path: '/work/xzcf/zfxc',
            name: 'zfxc',
            component: () => import('../pages/Work/xzcf/zfxc')
          },
          {
            path: '/work/xzcf/xcjc',
            name: 'xcjc',
            component: () => import('../pages/Work/xzcf/xcjc')
          },
          {
            path: '/work/xzcf/jycx',
            name: 'jycx',
            component: () => import('../pages/Work/xzcf/jycx')
          },
          {
            path: '/work/xzcf/ybcx',
            name: 'ybcx',
            component: () => import('../pages/Work/xzcf/ybcx')
          }
        ]
      },
      {
        path: '/Manager',
        name: 'Manager',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/Manager/jgdx',
            name: 'jgdx',
            component: () => import('../pages/Manager/jgdx')
          },
          {
            path: '/Manager/dwxxsz',
            name: 'dwxxsz',
            component: () => import('../pages/Manager/dwxxsz')
          },
          {
            path: '/Manager/dwry',
            name: 'dwry',
            component: () => import('../pages/Manager/dwry')
          },
          {
            path: '/Manager/DictConfig',
            name: 'DictConfig',
            component: () => import('../pages/Manager/DictConfig')
          },
          {
            path: '/Manager/commonItem',
            name: 'commonItem',
            component: () => import('../pages/Manager/commonItem')
          },
          {
            path: '/Manager/OrgConfig',
            name: 'OrgConfig',
            component: () => import('../pages/Manager/OrgConfig')
          },
          {
            path: '/Manager/UserManage/UserConfig',
            name: 'UserConfig',
            component: () => import('../pages/Manager/UserManage/UserConfig')
          },
          {
            path: '/Manager/UserManage/SafeConfig',
            name: 'SafeConfig',
            component: () => import('../pages/Manager/UserManage/SafeConfig')
          },
          {
            path: '/Manager/RoleConfig',
            name: 'RoleConfig',
            component: () => import('../pages/Manager/RoleConfig')
          },
          {
            path: '/Manager/ModulesConfig',
            name: 'ModulesConfig',
            component: () => import('../pages/Manager/ModulesConfig')
          },
          {
            path: '/Manager/DailyRecordManage',
            name: 'DailyRecordManage',
            component: () => import('../pages/Manager/DailyRecordManage')
          },
	        {
            path: '/Manager/TemplateManager',
            name: 'TemplateManager',
            component: () => import('../pages/Manager/TemplateManager')
          },
	        {
            path: '/Manager/ylaj',
            name: 'ylaj',
            component: () => import('../pages/Manager/ylaj')
          }
        ]
      }
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
